import {Route, Routes } from "react-router-dom";
import "./App.css";
import FormSubmiitted from "./Components/formsubmitted/FormSubmiitted";
import Form from "./Components/Form";
import Term from "./Components/Terms/Term";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";


function App() {
  return (
    <div className="App">
          <div className="Appinnerdiv">
       <div className='imgrankdiv '>
        <img src='/images/Audiologylogo.png'/>
    </div>
      <Routes>
        <Route path="/" element={<Form />} />
        <Route path="/success" element={<FormSubmiitted />} />
        <Route path="terms" element={<Term />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>

      
      <div className="footer_coptyright lg:flex items-center gap-5">
          <div className="footer">
            {" "}
            Copyright © 2024 Auxo Innovations. All rights Reserved.
          </div>
          <div className="privacy_link">
            <a href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>
          </div>
        </div>
    </div>
    </div>
  );
}

export default App;
